
import { defineComponent, onMounted, ref, watch } from "vue";
import Rating from "../Rating.vue";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router";
import axios from "axios";
import th from "@element-plus/locale/lang/th.js";
const userInfo = ref(null);
const modelEdit = ref({
  email: "",
  password: "",
  cpassword: "",
  prefix: "",
  firstName: "",
  lastName: "",
  idCard: "",
  birthDate: "",
  phoneNumber: "",
  regionId: "",
  provinceId: "",
  districtId: "",
  subDistrictId: "",
  provinceTh: "",
  provinceEn: "",
  districtTh: "",
  subDistrictTh: "",
  zipcode: "",
  road: "",
  no: "",
  moo: "",
  soi: "",
  qualificationId: "",
  qualificationOther: "",
  qualificationMajor: "",
  officeTypeId: "",
  officeExperience: "",
  officePosition: "",
  officeJobDesc: "",
  toc: false,
  imageProfile: "",
  file: null,
});
const modelMandatory = ref({
  facId: "",
  preFactoryNo: "",
  preBuildingNo: "",
  officeTypeId: "",
  factorySizeId: "",
  factoryTypes: [],
  factoryTypeOther: "",
  buildingSizeId: "",
  buildingTypes: [],
  buildingTypeOther: "",
  companyElectric: "",
  companyTransformer: "",
  companyHeat: "",
  companyFuel: "",
  machines: [],
  machineOther: "",
  technologies: [],
  technologieOther: "",
  warrantorFirstname: "",
  warrantorLastname: "",
  warrantorPosition: "",
  warrantorOffice: "",
  qualificationImage: [],
  faceImage: "",
  delImages: [],
  fileAvatar: null,
  fileImages: [],
  imagesList: [],
  facBuildTypeId: [],
});
const factoriesList = ref([]);
const factoryNow = ref({
  address: "",
  addressProfile: {},
  addressFac: {
    no: "",
    moo: "",
    soi: "",
    road: "",
    regionTh: "",
    provinceTh: "",
    districtTh: "",
    subDistrictTh: "",
    zipcode: "",
  },
});
const myCourseList = ref();
const syllabusList = ref();
const search = ref("");
const limit = ref(12);
const page = ref(0);
const totalPage = ref(0);
const listCertificate = ref();
const checkIdcard = ref(true);
const currentAdress = ref();
const dialogError = ref(false);
export default defineComponent({
  name: "profile",
  components: {
    Rating,
  },
  data() {
    return {
      locale: th,
      imageUrl: process.env.VUE_APP_IMG_URL,
      provinceList: [],
      districtList: [],
      subDistrictList: [],
      qualificationList: [],
      officeTypeList: [],
      myCourseSize: 0,
      factoriesList: [],
      buildingSizesList: [],
      buildingTypesList: [],
      technologiesList: [],
      machinesList: [],
      factorySizesList: [],
      factoryTypesList: [],
      factory: {
        addressProfile: {},
      },
      factoryBuildingTypesList: [],
      search: "",
      myCertificateList: [],
      rateId: null,
      rate1: 0,
      rate2: 0,
      rate3: 0,
      rate4: 0,
      localeConfig: {
        lang: th, // Set the language code (e.g., 'en' for English, 'fr' for French, etc.)
      },
      formpassword: false,
      validate: 0,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      validateCpassword: true,
      passwordFieldType: "password",
      passwordFieldType2: "password",
      passwordFieldType3: "password",
      prefixList: [],
      accessToken: null,
      regionList: [],
      listErrorApi: [],
    };
  },

  setup() {
    /* eslint-disable */
    const tab1 = ref(false);
    const router = useRouter();
    const route = useRoute();
    const tab = ref();
    onMounted(() => {
      if (localStorage.getItem("id_token") != null) {
        getCourseRegister();
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getSyllabusRegister?userId=" +
              localStorage.getItem("u_id"),
            {
              headers: {
                token: localStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => {
            syllabusList.value = response.data.data.content;
            // console.log("syllabusList");
            // console.log(syllabusList.value);
          })
          .catch((error) => {
            // console.log(error);
          });
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getProfile?id=" +
              localStorage.getItem("u_id"),
            {
              headers: {
                token: localStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => {
            // console.log("getProfile");
            // console.log(response.data.data);
            userInfo.value = response.data.data;
            modelEdit.value = response.data.data;
            if (
              modelEdit.value["imageProfile"] &&
              modelEdit.value["imageProfile"].indexOf("http") == -1
            ) {
              modelEdit.value["imageProfile"] =
                process.env.VUE_APP_IMG_URL + modelEdit.value["imageProfile"];
            }

            modelEdit.value.birthDate = setDate(modelEdit.value.birthDate) + "";

            // console.log("====================================");
            // console.log(modelEdit.value.birthDate);
            // console.log("====================================");
            modelEdit.value.no = modelEdit.value["address"]
              ? modelEdit.value["address"]["no"]
              : "";
            modelEdit.value.moo = modelEdit.value["address"]
              ? modelEdit.value["address"]["moo"]
              : "";
            modelEdit.value.soi = modelEdit.value["address"]
              ? modelEdit.value["address"]["soi"]
              : "";
            modelEdit.value.road = modelEdit.value["address"]
              ? modelEdit.value["address"]["road"]
              : "";
            modelEdit.value.regionId = modelEdit.value["address"]
              ? modelEdit.value["address"]["regionCode"]
              : "";
            modelEdit.value.provinceId = modelEdit.value["address"]
              ? modelEdit.value["address"]["provinceCode"]
              : "";
            modelEdit.value.districtId = modelEdit.value["address"]
              ? modelEdit.value["address"]["districtCode"]
              : "";
            modelEdit.value.subDistrictId = modelEdit.value["address"]
              ? modelEdit.value["address"]["subDistrictCode"]
              : "";
            modelEdit.value.zipcode = modelEdit.value["address"]
              ? modelEdit.value["address"]["zipcode"]
              : "";
            // specialist
            if (response.data.data.specialist) {
              // console.log(response.data.data.specialist);
              modelMandatory.value = response.data.data.specialist;
              // search.value =   modelMandatory.value.factoryProfile
              modelMandatory.value.facId = modelMandatory.value["factoryId"];
              const newfactoryType: any = [];
              if (modelMandatory.value.factoryTypes) {
                for (const loopData of modelMandatory.value.factoryTypes) {
                  newfactoryType.push(loopData["code"]);
                  if (loopData["other"] != "") {
                    modelMandatory.value.factoryTypeOther = loopData["other"];
                  }
                }
              }
              modelMandatory.value.factoryTypes = newfactoryType;

              const newbuildingType: any = [];
              if (modelMandatory.value.buildingTypes) {
                for (const loopData of modelMandatory.value.buildingTypes) {
                  newbuildingType.push(loopData["code"]);
                  if (loopData["other"] != "") {
                    modelMandatory.value.buildingTypeOther = loopData["other"];
                  }
                }
              }
              modelMandatory.value.buildingTypes = newbuildingType;

              const newmachine: any = [];
              if (modelMandatory.value.machines != null) {
                for (const loopData of modelMandatory.value.machines) {
                  newmachine.push(loopData["code"]);
                  if (loopData["other"] != "") {
                    modelMandatory.value.machineOther = loopData["other"];
                  }
                }
              }
              modelMandatory.value.machines = newmachine;

              const newtechnologie: any = [];
              if (modelMandatory.value.technologies != null) {
                for (const loopData of modelMandatory.value.technologies) {
                  newtechnologie.push(loopData["code"]);
                  if (loopData["other"] != "") {
                    modelMandatory.value.technologieOther = loopData["other"];
                  }
                }
              }
              modelMandatory.value.technologies = newtechnologie;

              const newqualificationImages: any = [];
              const listId: any = [];
              if (modelMandatory.value.qualificationImage) {
                for (const loopData of modelMandatory.value
                  .qualificationImage) {
                  listId.push(loopData);
                  newqualificationImages.push(
                    process.env.VUE_APP_IMG_URL + loopData
                  );
                }
              }
              modelMandatory.value.qualificationImage = newqualificationImages;
              modelMandatory.value.imagesList = listId;

              if (modelMandatory.value.faceImage != null) {
                modelMandatory.value.faceImage =
                  process.env.VUE_APP_IMG_URL + modelMandatory.value.faceImage;
              }
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        router.push({ name: "sign-in" });
      }
      if (route.query.tab) {
        tab.value = route.query.tab;
      } else {
        tab.value = 1;
      }
      // getDocuments();
      // getBookmark();
    });

    watch(
      () => route.query.tab,
      (newValue) => {
        if (newValue) {
          tab.value = newValue;
        } else {
          tab.value = 1;
        }
        router.go(0);
      }
    );
    const setDate = (birthDate) => {
      let date = new Date(modelEdit.value.birthDate);
      let data = "";
      data += date.getDate() + "/";
      if (date.getMonth() + 1 >= 10) {
        data += date.getMonth() + 1 + "/";
      } else {
        data += "0" + (date.getMonth() + 1) + "/";
      }
      data += date.getFullYear();
      // console.log("====================================");
      // console.log(data);
      // console.log("====================================");
      return data;
    };
    const getCourseRegister = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourseRegister?userId=" +
            localStorage.getItem("u_id") +
            "&page=" +
            page.value +
            "&sizeContents=" +
            limit.value,
          {
            headers: { token: localStorage.getItem("id_token") },
          }
        )
        .then((response) => {
          myCourseList.value = response.data.data.content;
          myCourseList.value["totalElements"] =
            response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      page.value = pageEvent - 1;
      getCourseRegister();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getCourseRegister();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getCourseRegister();
    };
    const searchData = () => {
      page.value = 0;
      getCourseRegister();
    };
    const checkRequire = () => {
      if (
        modelEdit.value.prefix &&
        modelEdit.value.firstName &&
        modelEdit.value.lastName &&
        modelEdit.value.phoneNumber &&
        checkIdcard.value == true
      ) {
        tab1.value = true;
      } else {
        tab1.value = false;
      }
      if (tab1.value) {
        return true;
      } else {
        return false;
      }
    };
    const onSubmitRegister = async () => {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if (checkRequire()) {
        let birthDate;
        birthDate =
          modelEdit.value.birthDate.split("/")[1] +
          "/" +
          modelEdit.value.birthDate.split("/")[0] +
          "/" +
          parseInt(modelEdit.value.birthDate.split("/")[2]);
        birthDate = new Date(birthDate).getTime();
        const formData = new FormData();
        formData.append("id ", modelEdit.value["id"]);
        formData.append(
          "prefix ",
          modelEdit.value.prefix != null ? modelEdit.value.prefix : ""
        );
        formData.append(
          "firstName",
          modelEdit.value.firstName != null ? modelEdit.value.firstName : ""
        );
        formData.append(
          "lastName",
          modelEdit.value.lastName != null ? modelEdit.value.lastName : ""
        );
        formData.append(
          "idCard",
          modelEdit.value.idCard != null ? modelEdit.value.idCard : ""
        );
        formData.append("birthDate", birthDate);
        formData.append("regionCode", modelEdit.value.regionId);
        formData.append("regionTh", currentAdress.value.region_c);
        formData.append("provinceCode", modelEdit.value.provinceId);
        formData.append("provinceTh", currentAdress.value.province_th);
        formData.append("provinceEn", currentAdress.value.province_en);
        formData.append("districtCode", modelEdit.value.districtId);
        formData.append("districtTh", currentAdress.value.city_th);
        formData.append("subDistrictCode", modelEdit.value.subDistrictId);
        formData.append("subDistrictTh", currentAdress.value.precinct_th);
        formData.append("zipcode", modelEdit.value.zipcode);
        formData.append("road", modelEdit.value.road);
        formData.append("no", modelEdit.value.no);
        formData.append("moo", modelEdit.value.moo);
        formData.append("soi", modelEdit.value.soi);
        formData.append("phoneNumber", modelEdit.value.phoneNumber);
        formData.append("qualificationId", modelEdit.value.qualificationId);
        formData.append(
          "qualificationOther",
          modelEdit.value.qualificationOther != null
            ? modelEdit.value.qualificationOther
            : ""
        );
        formData.append(
          "qualificationMajor",
          modelEdit.value.qualificationMajor != null
            ? modelEdit.value.qualificationMajor
            : ""
        );
        formData.append(
          "officeTypeId",
          modelEdit.value.officeTypeId != null
            ? modelEdit.value.officeTypeId
            : ""
        );
        formData.append(
          "officeExperience",
          modelEdit.value.officeExperience != null
            ? modelEdit.value.officeExperience
            : ""
        );
        formData.append(
          "officePosition",
          modelEdit.value.officePosition != null
            ? modelEdit.value.officePosition
            : ""
        );
        formData.append(
          "officeJobDesc",
          modelEdit.value.officeJobDesc != null
            ? modelEdit.value.officeJobDesc
            : ""
        );
        /* eslint-disable */
        if (modelEdit.value.file != null) {
          const formUploadImage = new FormData();
          formUploadImage.append("file ", modelEdit.value.file!);
          let uploadImage = await axios.post(
            process.env.VUE_APP_API_URL + "/uploadImage",
            formUploadImage,
            { headers: { token: localStorage.getItem("id_token") } }
          );
          // console.log(uploadImage);

          formData.append("imageProfile", uploadImage.data.data!);
        }
        axios
          .post(process.env.VUE_APP_API_URL + "/editProfile", formData, {
            headers: { token: localStorage.getItem("id_token") },
          })
          .then((res) => {
            if (res.data.code == 200) {
              const jsonUser = JSON.stringify({
                email: res.data.data.email,
                firstName: res.data.data.firstName,
                lastName: res.data.data.lastName,
                imageProfile: res.data.data.imageProfile,
              });
              localStorage.setItem("userInfo", jsonUser);
              if (modelMandatory.value.facId != "") {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                saveMandatory();
              } else {
                Swal.fire({
                  title: "บันทึกรายการสำเร็จ",
                  text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "ตกลง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-primary",
                  },
                }).then(function(res) {
                  router.go(0);
                });
              }
            } else {
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: res.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      } else {
        Swal.close();
        // console.log("not-submit");
      }
    };
    const onSubmitSpecialist = async () => {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if (checkRequire()) {
        let birthDate;
        birthDate =
          modelEdit.value.birthDate.split("/")[1] +
          "/" +
          modelEdit.value.birthDate.split("/")[0] +
          "/" +
          parseInt(modelEdit.value.birthDate.split("/")[2]);
        birthDate = new Date(birthDate).getTime();
        const formData = new FormData();
        formData.append("id ", modelEdit.value["id"]);
        formData.append(
          "prefix ",
          modelEdit.value.prefix != null ? modelEdit.value.prefix : ""
        );
        formData.append(
          "firstName",
          modelEdit.value.firstName != null ? modelEdit.value.firstName : ""
        );
        formData.append(
          "lastName",
          modelEdit.value.lastName != null ? modelEdit.value.lastName : ""
        );
        formData.append(
          "idCard",
          modelEdit.value.idCard != null ? modelEdit.value.idCard : ""
        );

        formData.append("birthDate", birthDate);
        formData.append("regionCode", modelEdit.value.regionId);
        formData.append("regionTh", currentAdress.value.region_c);
        formData.append("provinceCode", modelEdit.value.provinceId);
        formData.append("provinceTh", currentAdress.value.province_th);
        formData.append("provinceEn", currentAdress.value.province_en);
        formData.append("districtCode", modelEdit.value.districtId);
        formData.append("districtTh", currentAdress.value.city_th);
        formData.append("subDistrictCode", modelEdit.value.subDistrictId);
        formData.append("subDistrictTh", currentAdress.value.precinct_th);
        formData.append("zipcode", modelEdit.value.zipcode);
        formData.append("road", modelEdit.value.road);
        formData.append("no", modelEdit.value.no);
        formData.append("moo", modelEdit.value.moo);
        formData.append("soi", modelEdit.value.soi);
        formData.append("phoneNumber", modelEdit.value.phoneNumber);
        formData.append("qualificationId", modelEdit.value.qualificationId);
        formData.append(
          "qualificationOther",
          modelEdit.value.qualificationOther != null
            ? modelEdit.value.qualificationOther
            : ""
        );
        formData.append(
          "qualificationMajor",
          modelEdit.value.qualificationMajor != null
            ? modelEdit.value.qualificationMajor
            : ""
        );
        formData.append(
          "officeTypeId",
          modelEdit.value.officeTypeId != null
            ? modelEdit.value.officeTypeId
            : ""
        );
        formData.append(
          "officeExperience",
          modelEdit.value.officeExperience != null
            ? modelEdit.value.officeExperience
            : ""
        );
        formData.append(
          "officePosition",
          modelEdit.value.officePosition != null
            ? modelEdit.value.officePosition
            : ""
        );
        formData.append(
          "officeJobDesc",
          modelEdit.value.officeJobDesc != null
            ? modelEdit.value.officeJobDesc
            : ""
        );
        /* eslint-disable */
        if (modelEdit.value.file != null) {
          const formUploadImage = new FormData();
          formUploadImage.append("file ", modelEdit.value.file!);
          let uploadImage = await axios.post(
            process.env.VUE_APP_API_URL + "/uploadImage",
            formUploadImage,
            { headers: { token: localStorage.getItem("id_token") } }
          );
          // console.log(uploadImage);

          formData.append("imageProfile", uploadImage.data.data!);
        }
        axios
          .post(process.env.VUE_APP_API_URL + "/editProfile", formData, {
            headers: { token: localStorage.getItem("id_token") },
          })
          .then((res) => {
            if (res.data.code == 200) {
              const jsonUser = JSON.stringify({
                email: res.data.data.email,
                firstName: res.data.data.firstName,
                lastName: res.data.data.lastName,
                imageProfile: res.data.data.imageProfile,
              });
              localStorage.setItem("userInfo", jsonUser);
              if (modelMandatory.value.facId != "") {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                saveMandatoryTrue();
              } else {
                Swal.fire({
                  title: "บันทึกรายการสำเร็จ",
                  text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "ตกลง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-primary",
                  },
                }).then(function(res) {
                  router.go(0);
                });
              }
            } else {
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: res.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      } else {
        Swal.close();
      }
    };

    /* eslint-disable */
    const saveMandatory = () => {
      const factoryTypes: any = [];
      for (const loopFactory of modelMandatory.value.factoryTypes) {
        if (loopFactory != undefined) {
          factoryTypes.push({
            code: loopFactory,
            other:
              loopFactory == "10" ? modelMandatory.value.factoryTypeOther : "",
          });
        }
      }

      const buildingTypes: any = [];
      for (const loopBuild of modelMandatory.value.buildingTypes) {
        if (loopBuild != undefined) {
          buildingTypes.push({
            code: loopBuild,
            other:
              loopBuild == "6" ? modelMandatory.value.buildingTypeOther : "",
          });
        }
      }

      const machinesMap: any = [];
      if (modelMandatory.value.machines != null) {
        for (const loopMachine of modelMandatory.value.machines) {
          if (loopMachine != undefined) {
            machinesMap.push({
              code: loopMachine,
              other:
                loopMachine == "6" ? modelMandatory.value.machineOther : "",
            });
          }
        }
      }

      let technologiesMap: any = [];
      if (modelMandatory.value.technologies != null) {
        for (const loopTech of modelMandatory.value.technologies) {
          if (loopTech != undefined) {
            technologiesMap.push({
              code: loopTech,
              other:
                loopTech == "6" ? modelMandatory.value.technologieOther : "",
            });
          }
        }
      }

      const formData = new FormData();
      formData.append("requestSpecialist", "false");
      formData.append("factoryId", modelMandatory.value.facId);
      formData.append(
        "preFactoryNo",
        modelMandatory.value.preFactoryNo != null
          ? modelMandatory.value.preFactoryNo
          : ""
      );
      formData.append(
        "preBuildingNo",
        modelMandatory.value.preBuildingNo != null
          ? modelMandatory.value.preBuildingNo
          : ""
      );
      formData.append(
        "officeTypeId",
        modelMandatory.value.officeTypeId != null
          ? modelMandatory.value.officeTypeId
          : ""
      );
      formData.append(
        "factorySizeId",
        modelMandatory.value.factorySizeId != null
          ? modelMandatory.value.factorySizeId
          : ""
      );
      formData.append("factoryTypes", JSON.stringify(factoryTypes));
      formData.append(
        "buildingSizeId",
        modelMandatory.value.buildingSizeId != null
          ? modelMandatory.value.buildingSizeId
          : ""
      );
      formData.append("buildingTypes", JSON.stringify(buildingTypes));
      formData.append("companyElectric", modelMandatory.value.companyElectric);
      formData.append(
        "companyTransformer",
        modelMandatory.value.companyTransformer
      );
      formData.append("companyHeat", modelMandatory.value.companyHeat);
      formData.append("companyFuel", modelMandatory.value.companyFuel);
      formData.append("machines", JSON.stringify(machinesMap));
      formData.append("technologies", JSON.stringify(technologiesMap));
      formData.append(
        "warrantorFirstname",
        modelMandatory.value.warrantorFirstname
      );
      formData.append(
        "warrantorLastname",
        modelMandatory.value.warrantorLastname
      );
      formData.append(
        "warrantorPosition",
        modelMandatory.value.warrantorPosition
      );
      formData.append("warrantorOffice", modelMandatory.value.warrantorOffice);
      if (modelMandatory.value.fileImages) {
        for (const loopfile of modelMandatory.value.fileImages) {
          formData.append("qualificationImage", loopfile);
        }
      }
      /* eslint-disable */
      if (modelMandatory.value.fileAvatar != null) {
        formData.append("faceImage", modelMandatory.value.fileAvatar!);
      }
      if (modelMandatory.value.delImages) {
        for (const loopfile of modelMandatory.value.delImages) {
          formData.append("deleteQualificationImage", loopfile);
        }
      }
      axios
        .post(process.env.VUE_APP_API_URL + "/editSpecialist", formData, {
          headers: { token: localStorage.getItem("id_token") },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: "บันทึกรายการสำเร็จ",
              text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function(res) {
              router.go(0);
            });
          } else {
            Swal.fire({
              title: "บันทึกรายการสำเร็จ",
              text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              router.go(-1);
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const saveMandatoryTrue = () => {
      const factoryTypes: any = [];
      for (const loopFactory of modelMandatory.value.factoryTypes) {
        if (loopFactory != undefined) {
          factoryTypes.push({
            code: loopFactory,
            other:
              loopFactory == "10" ? modelMandatory.value.factoryTypeOther : "",
          });
        }
      }

      const buildingTypes: any = [];
      for (const loopBuild of modelMandatory.value.buildingTypes) {
        if (loopBuild != undefined) {
          buildingTypes.push({
            code: loopBuild,
            other:
              loopBuild == "6" ? modelMandatory.value.buildingTypeOther : "",
          });
        }
      }

      let machinesMap: any = [];
      if (modelMandatory.value.machines != null) {
        for (const loopMachine of modelMandatory.value.machines) {
          if (loopMachine != undefined) {
            machinesMap.push({
              code: loopMachine,
              other:
                loopMachine == "6" ? modelMandatory.value.machineOther : "",
            });
          }
        }
      }

      let technologiesMap: any = [];
      if (modelMandatory.value.technologies != null) {
        for (const loopTech of modelMandatory.value.technologies) {
          if (loopTech != undefined) {
            technologiesMap.push({
              code: loopTech,
              other:
                loopTech == "6" ? modelMandatory.value.technologieOther : "",
            });
          }
        }
      }

      const formData = new FormData();
      formData.append("requestSpecialist", "true");
      formData.append("factoryId", modelMandatory.value.facId);
      formData.append(
        "preFactoryNo",
        modelMandatory.value.preFactoryNo != null
          ? modelMandatory.value.preFactoryNo
          : ""
      );
      formData.append(
        "preBuildingNo",
        modelMandatory.value.preBuildingNo != null
          ? modelMandatory.value.preBuildingNo
          : ""
      );
      formData.append(
        "officeTypeId",
        modelMandatory.value.officeTypeId != null
          ? modelMandatory.value.officeTypeId
          : ""
      );
      formData.append(
        "factorySizeId",
        modelMandatory.value.factorySizeId != null
          ? modelMandatory.value.factorySizeId
          : ""
      );
      formData.append("factoryTypes", JSON.stringify(factoryTypes));
      formData.append(
        "buildingSizeId",
        modelMandatory.value.buildingSizeId != null
          ? modelMandatory.value.buildingSizeId
          : ""
      );
      formData.append("buildingTypes", JSON.stringify(buildingTypes));
      formData.append("companyElectric", modelMandatory.value.companyElectric);
      formData.append(
        "companyTransformer",
        modelMandatory.value.companyTransformer
      );
      formData.append(
        "companyHeat",
        modelMandatory.value.companyHeat != null
          ? modelMandatory.value.companyHeat
          : ""
      );
      formData.append(
        "companyFuel",
        modelMandatory.value.companyFuel != null
          ? modelMandatory.value.companyFuel
          : ""
      );
      formData.append("machines", JSON.stringify(machinesMap));
      formData.append("technologies", JSON.stringify(technologiesMap));
      formData.append(
        "warrantorFirstname",
        modelMandatory.value.warrantorFirstname
      );
      formData.append(
        "warrantorLastname",
        modelMandatory.value.warrantorLastname
      );
      formData.append(
        "warrantorPosition",
        modelMandatory.value.warrantorPosition
      );
      formData.append("warrantorOffice", modelMandatory.value.warrantorOffice);
      if (modelMandatory.value.fileImages) {
        for (const loopfile of modelMandatory.value.fileImages) {
          formData.append("qualificationImage", loopfile);
        }
      }
      /* eslint-disable */
      if (modelMandatory.value.fileAvatar != null) {
        formData.append("faceImage", modelMandatory.value.fileAvatar!);
      }
      if (modelMandatory.value.delImages) {
        for (const loopfile of modelMandatory.value.delImages) {
          formData.append("deleteQualificationImage", loopfile);
        }
      }
      axios
        .post(process.env.VUE_APP_API_URL + "/editSpecialist", formData, {
          headers: { token: localStorage.getItem("id_token") },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: "บันทึกรายการสำเร็จ",
              text: "กรุณารอการอนุมัติข้อมูลจากแอดมิน",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function(res) {
              router.go(0);
            });
          } else {
            Swal.fire({
              title: "บันทึกรายการสำเร็จ",
              text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              router.go(-1);
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const callFactories = async (val) => {
      // console.log(val);
      if (val.length > 2) {
        const factories = await axios.get(
          process.env.VUE_APP_API_URL +
            `/getFactory?sizeContents=100&keyWord=${val}`,
          {
            headers: { token: localStorage.getItem("id_token") },
          }
        );
        // console.log(factories.data.data.content);
        factoriesList.value = factories.data.data.content;
      }
    };
    const callFactoriesId = async () => {
      modelMandatory.value.factoryTypes = [];
      modelMandatory.value.buildingTypes = [];
      const factory = await axios.get(
        process.env.VUE_APP_API_URL +
          `/getFactory?facId=${modelMandatory.value.facId}`,
        {
          headers: { token: localStorage.getItem("id_token") },
        }
      );
      // console.log(factory.data.data);
      factoryNow.value = factory.data.data.content[0];
      search.value = factoryNow.value["factoryName"];
      let facBuildTypeId: any = [];
      facBuildTypeId.push(factoryNow.value["facBuildTypeId"]);
      // console.log(factoryNow.value["facBuildTypeId"]);
      if (factoryNow.value["type"] == "โรงงาน") {
        modelMandatory.value.facBuildTypeId = facBuildTypeId;
      } else {
        modelMandatory.value.facBuildTypeId = facBuildTypeId;
      }
      if (!factoryNow.value["addressFac"]) {
        factoryNow.value["addressFac"] = {
          no: "",
          moo: "",
          soi: "",
          road: "",
          regionTh: "",
          provinceTh: "",
          districtTh: "",
          subDistrictTh: "",
          zipcode: "",
        };
      }

      // modelMandatory.value.factoryTypes
      callFactories(search.value);
    };
    const getCertificateId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getCourseRegister?id=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: { token: localStorage.getItem("id_token") },
        }
      );
      console.log("downloadCertificate");
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate();
    };
    const getCertificateSyllabusId = async (id) => {
      const certificate = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getSyllabusRegister?id=" +
          id +
          "&userId=" +
          localStorage.getItem("u_id"),
        {
          headers: { token: localStorage.getItem("id_token") },
        }
      );
      // console.log("certificate");
      // console.log(certificate.data.data.content[0]);
      listCertificate.value = certificate.data.data.content[0];
      downloadCertificate2();
    };
    const downloadCertificate = async () => {
      const director = await axios.get(
        process.env.VUE_APP_API_URL + "/getDirector",
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      console.log(director);
      let date: String =
        new Date(listCertificate.value.certApproveDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
      let course = listCertificate.value.courseProfile.title;
      const url =
        process.env.VUE_APP_REPORT_URL +
        "&title=" +
        listCertificate.value.authorProfile.prefix +
        "&course=" +
        course +
        "&firstname=" +
        listCertificate.value.authorProfile.firstName +
        "&lastname=" +
        listCertificate.value.authorProfile.lastName +
        "&date=" +
        arabic_to_thai(date) +
        "&license=" +
        process.env.VUE_APP_IMG_URL +
        director.data.data.signature +
        "&licenseName=" +
        director.data.data.firstName +
        " " +
        director.data.data.lastName +
        "&position=" +
        director.data.data.position;
      window.open(url.replace("/fileName", "/cert_dede2.pdf"), "_blank");
    };
    const downloadCertificate2 = async () => {
      let finishDate = "-";
      console.log(listCertificate.value);
      const director = await axios.get(
        process.env.VUE_APP_API_URL + "/getDirector",
        {
          headers: {
            token: localStorage.getItem("id_token"),
          },
        }
      );
      let date: String =
        new Date(listCertificate.value.certApproveDate).toLocaleDateString(
          "th-TH",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ) + "";
      if (listCertificate.value.finishDate != null) {
        finishDate =
          new Date(listCertificate.value.finishDate).toLocaleDateString(
            "th-TH",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          ) + "";
      }
      let course = listCertificate.value.syllabusProfile.name.replace(
        "หลักสูตร",
        ""
      );
      const url =
        process.env.VUE_APP_REPORT_URL +
        "&title=" +
        listCertificate.value.authorProfile.prefix +
        "&course=" +
        course +
        "&firstname=" +
        listCertificate.value.authorProfile.firstName +
        "&lastname=" +
        listCertificate.value.authorProfile.lastName +
        "&date=" +
        arabic_to_thai(date) +
        "&license=" +
        process.env.VUE_APP_IMG_URL +
        director.data.data.signatureSyllabus +
        "&licenseName=" +
        director.data.data.firstNameSyllabus +
        " " +
        director.data.data.lastNameSyllabus +
        "&position=" +
        director.data.data.positionSyllabus +
        "&examDate=" +
        arabic_to_thai(finishDate) +
        "&certNo=" +
        listCertificate.value.certNo;
      window.open(url.replace("/fileName", "/cert_dede.pdf"), "_blank");
    };
    const assertThaiId = (thaiId: string): boolean => {
      // console.log("assertThaiId : " + thaiId);
      const m = thaiId.match(/(\d{12})(\d)/);
      if (!m) {
        console.warn("Bad input from user, invalid thaiId=", thaiId);
        throw new Error("thai-id-must-be-13-digits");
      }
      const digits = m[1].split("");
      const sum = digits.reduce((total: number, digit: string, i: number) => {
        return total + (13 - i) * +digit;
      }, 0);
      const lastDigit = `${(11 - (sum % 11)) % 10}`;
      const inputLastDigit = m[2];
      if (lastDigit !== inputLastDigit) {
        console.warn("Bad input from user, invalid checksum thaiId=", thaiId);
        // throw new Error("thai-id-checksum-mismatched");
        checkIdcard.value = false;
        return false;
      } else {
        checkIdcard.value = true;
        return true;
      }
    };
    const arabic_to_thai = (text) => {
      let data = text
        .replaceAll("1", "๑")
        .replaceAll("2", "๒")
        .replaceAll("3", "๓")
        .replaceAll("4", "๔")
        .replaceAll("5", "๕")
        .replaceAll("6", "๖")
        .replaceAll("7", "๗")
        .replaceAll("8", "๘")
        .replaceAll("9", "๙")
        .replaceAll("0", "๐");
      return data;
    };
    return {
      route,
      userInfo,
      modelEdit,
      onSubmitRegister,
      checkRequire,
      modelMandatory,
      saveMandatory,
      saveMandatoryTrue,
      onSubmitSpecialist,
      tab,
      callFactoriesId,
      callFactories,
      search,
      factoriesList,
      factoryNow,
      myCourseList,
      syllabusList,
      getCourseRegister,
      getCertificateId,
      listCertificate,
      downloadCertificate,
      tab1,
      getCertificateSyllabusId,
      assertThaiId,
      checkIdcard,
      arabic_to_thai,
      changePage,
      backPage,
      nextPage,
      searchData,
      limit,
      page,
      totalPage,
      currentAdress,
      dialogError,
    };
  },
  methods: {
    validateThai(input, value) {
      const thaiRegex = /^[\u0E00-\u0E7F]+$/;
      let check = thaiRegex.test(value);
      if (input == "firstName" && check != true) {
        modelEdit.value.firstName = "";
      }
      if (input == "lastName" && check != true) {
        modelEdit.value.lastName = "";
      }
    },
    formatDate(date) {
      const event = new Date(date);
      let dateString =
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }) + "";
      return dateString;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    switchVisibility2() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
    },
    switchVisibility3() {
      this.passwordFieldType3 =
        this.passwordFieldType3 === "password" ? "text" : "password";
    },

    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
    setAltImgCover(event) {
      event.target.src = "media/image-app/no-data.svg";
    },
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    onFileChange(event) {
      modelEdit.value.file = event.target.files[0];
      modelEdit.value.imageProfile = URL.createObjectURL(event.target.files[0]);
    },
    onFileChangeMultiple(event) {
      const deleteFile: any = [];
      if (modelMandatory.value.imagesList) {
        for (const loopDel of modelMandatory.value.imagesList) {
          deleteFile.push(loopDel);
        }
      }
      modelMandatory.value.delImages = deleteFile;
      modelMandatory.value.fileImages = event.target.files;
      if (event.target.files.length > 0 || event.target.files.length == 3) {
        const image: any = [];
        for (const loopFile of event.target.files) {
          image.push(URL.createObjectURL(loopFile));
        }
        modelMandatory.value.qualificationImage = image;
      } else {
        Swal.fire({
          title: "แจ้งเตือนข้อผิดพลาด",
          text: "จำนวนรูปไม่ถูกต้อง แนบไฟล์จำนวน 3 ไฟล์",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }
    },
    onFileChangeAvatar(event) {
      modelMandatory.value.fileAvatar = event.target.files[0];
      modelMandatory.value.faceImage = URL.createObjectURL(
        event.target.files[0]
      );
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      // eslint-disable-next-line prefer-const
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async callProvince(regionId, clear) {
      if (clear == true) {
        modelEdit.value.provinceId = "";
        modelEdit.value.districtId = "";
        modelEdit.value.subDistrictId = "";
        modelEdit.value.zipcode = "";
      }
      const provinces = await axios.get(
        "https://etraining.dede.go.th/api/xProvince?region_c=" + regionId,
        {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        }
      );
      this.provinceList = provinces.data;
    },
    async callDistrict(provinceId, clear) {
      if (clear == true) {
        modelEdit.value.districtId = "";
        modelEdit.value.subDistrictId = "";
        modelEdit.value.zipcode = "";
      }
      const districts = await axios.get(
        process.env.VUE_APP_API_URL +
          "/getDistrictTraining?province_c=" +
          provinceId
      );
      this.districtList = districts.data.data;
    },
    async callSubDistrict(districtId, clear) {
      if (clear == true) {
        modelEdit.value.subDistrictId = "";
        modelEdit.value.zipcode = "";
      }
      // let subDistricts = await axios.get(
      //   `https://etraining.dede.go.th/api/xAdm_districts?province_c=${modelEdit.value.provinceId}&city_c=${districtId}`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + this.accessToken,
      //     },
      //   }
      // );
      // this.subDistrictList = subDistricts.data;
      try {
      let response = await axios.get(
        `https://etraining.dede.go.th/api/xAdm_districts?province_c=${modelEdit.value.provinceId}&city_c=${districtId}`,
        {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        }
      );
      this.subDistrictList = response.data;
    } catch (error) {
      (this.listErrorApi as any).push({
        api:"https://etraining.dede.go.th/api/xAdm_districts",
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      });
    }
    },
    callZipcode(subDistrictId, clear) {
      if (clear == true) {
        modelEdit.value.zipcode = "";
      }
      if (this.subDistrictList.length > 0) {
        for (const loopData of this.subDistrictList) {
          if (loopData["precinct_c"] == subDistrictId) {
            currentAdress.value = loopData;
            modelEdit.value.zipcode = loopData["post_code"];
          }
        }
      }
    },

    // async callFactories() {
    //   const factories = await axios.get(
    //     process.env.VUE_APP_API_URL + `/getFactory?sizeContents=99999&keyWord=${this.search}`
    //   ,{
    //     headers:headers
    //   });
    // console.log(factories.data.data.content);
    //   this.factoriesList = factories.data.data.content;
    // },
    // async callFactoriesId() {
    //   const factory = await axios.get(
    //     process.env.VUE_APP_API_URL +
    //       `/getFactory?id=${modelMandatory.value.facId}`
    //   ,{
    //     headers:headers
    //   });
    // console.log(factory.data.data);
    //   this.factory = factory.data.data.content[0];
    //   this.search = this.factory["factoryName"];
    //   this.callFactories();
    // },
    setBuildingTypes(id) {
      if (
        modelMandatory.value &&
        modelMandatory.value.buildingTypes.length > 0
      ) {
        for (const loopdata of modelMandatory.value.buildingTypes) {
          if (loopdata["buildingTypeId"] == id) {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    fileMultipleClick() {
      // console.log(this.$refs.fileMultiple);
      const input: any = this.$refs.fileMultiple;
      input.click();
    },
    fileAvatarClick() {
      // console.log(this.$refs.fileAvatar);
      const input: any = this.$refs.fileAvatar;
      input.click();
    },
    fileClick() {
      // console.log(this.$refs.file);
      const input: any = this.$refs.file;
      input.click();
    },
    renderCategory(list) {
      let data: any = [];
      if (list.length > 0) {
        for (const looplist of list) {
          data.push(looplist[0].data);
        }
      }
      if (data.length > 0) {
        return data.join(",");
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#9999D3'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#31B67C'>เรียนจบ</span>";
      } else if (status == 4) {
        return "<span style='color:#FF7786'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#F2A17D'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
    handleData1(data) {
      this.rate1 = data;
    },
    handleData2(data) {
      this.rate2 = data;
    },
    handleData3(data) {
      this.rate3 = data;
    },
    handleData4(data) {
      this.rate4 = data;
    },
    async setId(id) {
      this.rateId = id;
      this.rate1 = 0;
      this.rate2 = 0;
      this.rate3 = 0;
      this.rate4 = 0;
    },
    async sentScore() {
      const formData = new FormData();
      formData.append("id", this.rateId + "");
      formData.append("rate1", this.rate1 + "");
      formData.append("rate2", this.rate2 + "");
      formData.append("rate3", this.rate3 + "");
      formData.append("rate4", this.rate4 + "");
      formData.append("remark", "");

      await axios
        .post(process.env.VUE_APP_API_URL + "/updateCourseRate", formData, {
          headers: { token: localStorage.getItem("id_token") },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: "บันทึกรายการสำเร็จ",
              text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              router.go(0);
            });
          } else {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: res.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    },
    onBack() {
      this.$router.go(-1);
    },
    showForm(data) {
      this.formpassword = data;
    },
    validatePassword() {
      this.validate = 0;
      const letterRegex = /[a-zA-Z]/;
      const numberRegex = /[0-9]/;
      const symbolRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

      const isValidLength = this.newPassword.length >= 8;
      const hasLetter = letterRegex.test(this.newPassword);
      const hasNumber = numberRegex.test(this.newPassword);
      const hasSymbol = symbolRegex.test(this.newPassword);

      if (isValidLength) {
        this.validate = this.validate + 1;
      }
      if (hasLetter) {
        this.validate = this.validate + 1;
      }
      if (hasNumber) {
        this.validate = this.validate + 1;
      }
      if (hasSymbol) {
        this.validate = this.validate + 1;
      }
      // console.log("validatePassword");
      // console.log(this.validate);
      // return isValidLength && hasLetter && hasNumber && hasSymbol;
    },
    validateConfirmpassword() {
      if (this.newPassword == this.newPasswordConfirm) {
        this.validateCpassword = true;
      } else {
        this.validateCpassword = false;
      }
    },
    async changePassword() {
      const formData = new FormData();
      formData.append("oldPassword", this.oldPassword);
      formData.append("newPassword", this.newPassword);
      formData.append("newPasswordConfirm", this.newPasswordConfirm);

      await axios
        .post(process.env.VUE_APP_API_URL + "/changeUserPassword", formData, {
          headers: { token: localStorage.getItem("id_token") },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: "เปลี่ยนรหัสผ่านสำเร็จ",
              text: "รหัสผ่านถูกเปลี่ยนแล้ว",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(() => {
              this.oldPassword = "";
              this.newPassword = "";
              this.newPasswordConfirm = "";
              this.formpassword = false;
            });
          } else {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: res.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    },
    onBaclk() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    // if (localStorage.getItem("reloaded")) {
    //   location.reload();
    //   localStorage.removeItem("reloaded");
    // }
    Swal.fire({
        title: "กำลังโหลดข้อมูล",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    const qualifications = await axios.get(
      process.env.VUE_APP_API_URL + "/getQualification"
    );
    this.qualificationList = qualifications.data.data;

    const officeTypes = await axios.get(
      process.env.VUE_APP_API_URL + "/getOfficeType"
    );
    this.officeTypeList = officeTypes.data.data;
    const buildingSizes = await axios.get(
      process.env.VUE_APP_API_URL + "/getBuildingSize"
    );
    this.buildingSizesList = buildingSizes.data.data;

    const buildingTypes = await axios.get(
      process.env.VUE_APP_API_URL + "/getBuildingTypes"
    );
    this.buildingTypesList = buildingTypes.data.data;

    const factorySizes = await axios.get(
      process.env.VUE_APP_API_URL + "/getFactorySize"
    );
    this.factorySizesList = factorySizes.data.data;

    const factoryTypes = await axios.get(
      process.env.VUE_APP_API_URL + "/getFactoryTypes"
    );
    this.factoryTypesList = factoryTypes.data.data;

    const technologies = await axios.get(
      process.env.VUE_APP_API_URL + "/getTechnologies"
    );
    this.technologiesList = technologies.data.data;

    const machines = await axios.get(
      process.env.VUE_APP_API_URL + "/getMachines"
    );
    this.machinesList = machines.data.data;

    const factoryBuildingTypes = await axios.get(
      process.env.VUE_APP_API_URL + "/getFactoryBuildingTypes"
    );
    this.factoryBuildingTypesList = factoryBuildingTypes.data.data;

    if (modelMandatory.value.facId) {
      this.callFactoriesId();
    }

    // let etrainingToken = await axios.get(
    //   process.env.VUE_APP_API_URL + "/connectTrainingToken"
    // );
    // this.accessToken = etrainingToken.data.data.access_token;

    try {
      let response = await axios.get(
        process.env.VUE_APP_API_URL + "/connectTrainingToken",
       
      );
      if (response.data.data != null) {
        this.accessToken = response.data.data.access_token;
      }else{
        (this.listErrorApi as any).push({
        api:process.env.VUE_APP_API_URL + "/connectTrainingToken",
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      });
      }
    } catch (error) {
      (this.listErrorApi as any).push({
        api:process.env.VUE_APP_API_URL + "/connectTrainingToken",
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      });
    }

    try {
      let response = await axios.get(
        "https://etraining.dede.go.th/api/xPrename",
        {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        }
      );

      this.prefixList = response.data; // เก็บข้อมูลลงในตัวแปร หรือจัดการตามต้องการ
    } catch (error) {
      (this.listErrorApi as any).push({
        api:"https://etraining.dede.go.th/api/xPrename",
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      });
    }
    // let xRegion = await axios.get("https://etraining.dede.go.th/api/xRegion", {
    //   headers: {
    //     Authorization: "Bearer " + this.accessToken,
    //   },
    // });
    try {
      let response = await axios.get(
        "https://etraining.dede.go.th/api/xRegion",
        {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        }
      );
      this.regionList = response.data;
    } catch (error) {
      (this.listErrorApi as any).push({
        api:"https://etraining.dede.go.th/api/xRegion",
        message: "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้",
      });
    }

    if (modelEdit.value.regionId && this.regionList && this.regionList.length > 0) {
      await this.callProvince(modelEdit.value.regionId, false);
    }
    if (modelEdit.value.provinceId && this.provinceList && this.provinceList.length > 0) {
      await this.callDistrict(modelEdit.value.provinceId, false);
    }
    if (modelEdit.value.districtId && this.districtList && this.districtList.length > 0) {
      await this.callSubDistrict(modelEdit.value.districtId, false);
    }
    if (modelEdit.value.subDistrictId && this.subDistrictList && this.subDistrictList.length > 0) {
      await this.callZipcode(modelEdit.value.subDistrictId, false);
    }
    console.log(this.listErrorApi);
    
    if (this.listErrorApi && this.listErrorApi.length > 0) {
      dialogError.value = true
      Swal.close();
    } else {
      dialogError.value = false
      Swal.close();
    }
  },
  beforeUnmount() {
    this.listErrorApi = []
    dialogError.value = false
    Swal.close();
  },
});
